import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout/index';
import PrivateRoute from 'layout/PrivateLayout/index';

// render - event page
const EventPage = Loadable(lazy(() => import('pages/event')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/event',
    element: <MainLayout />,
    children: [
        {
            path: ':eventId/:eventName',
            element: (
                <PrivateRoute>
                    <EventPage />
                </PrivateRoute>
            )
        }
    ]
};

export default MainRoutes;
