import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { isRefreshTokenExpired } from 'utils/auth';

const PrivateRoute = ({ children }) => {
    const { isLoggedIn } = useSelector((state) => state.account);
    const refresh_token = Cookies.get('refresh_token');
    const loggedIn = isLoggedIn && !isRefreshTokenExpired(refresh_token);
    return loggedIn ? <>{children}</> : <Navigate to="/login" />;
};

export default PrivateRoute;
