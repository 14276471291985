import axios from './axios';
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';

export const setAuthUser = (access_token, access_exp, refresh_token, refresh_exp) => {
    Cookies.set('access_token', access_token, {
        expires: new Date(access_exp * 1000),
        secure: true
    });

    if (refresh_token) {
        Cookies.set('refresh_token', refresh_token, {
            expires: new Date(refresh_exp * 1000),
            secure: true
        });
    }
};

export const getRefreshToken = async () => {
    const refresh_token = Cookies.get('refresh_token');
    if (!refresh_token) return null;
    const response = await axios.post('token/refresh/', {
        refresh: refresh_token
    });
    return response.data;
};

export const isAccessTokenExpired = (accessToken) => {
    if (!accessToken) return true;
    try {
        const decodedToken = jwt_decode(accessToken);
        return decodedToken.exp < Date.now() / 1000;
    } catch (err) {
        return true; // Token is invalid or expired
    }
};

export const isRefreshTokenExpired = (refreshToken) => {
    if (!refreshToken) return true;
    try {
        const decodedToken = jwt_decode(refreshToken);
        return decodedToken.exp < Date.now() / 1000;
    } catch (err) {
        return true; // Token is invalid or expired
    }
};
