import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// render - login
const AuthSignUp = Loadable(lazy(() => import('pages/authentication/Signup')));
const VerifyEmail = Loadable(lazy(() => import('pages/authentication/VerifyEmail')));

// ==============================|| AUTH ROUTING ||============================== //

const SignupRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: 'signup',
            element: <AuthSignUp />
        },
        {
            path: 'verify/:uid/:token',
            element: <VerifyEmail />
        }
    ]
};

export default SignupRoutes;
