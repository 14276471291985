import axios from 'axios';

const apiInstance = axios.create({
    baseURL: '/api/',
    timeout: 10000, // timeout after 10 seconds
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }
});

export default apiInstance;
