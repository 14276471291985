// third-party
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project import
import reducers from './reducers';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['account'] // only persist the account reducer
};

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const persistedReducer = persistReducer(persistConfig, reducers);
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'], // Ignore persist actions
                ignoredPaths: ['account'] // If 'account' contains non-serializable values, ignore it
            }
        })
});

const persistor = persistStore(store);

export { store, persistor };
