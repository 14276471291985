// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import './App.css';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //
const App = () => (
    <ThemeCustomization>
        <Routes />
    </ThemeCustomization>
);

export default App;
