import { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useAxios from 'utils/useAxios';
import Cookies from 'js-cookie';
import { isRefreshTokenExpired } from 'utils/auth';

const UserLossesContext = createContext();

export function UserLossesProvider({ children }) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const api = useAxios();
    const { isLoggedIn } = useSelector((state) => state.account);
    const refresh_token = Cookies.get('refresh_token');
    const loggedIn = isLoggedIn && !isRefreshTokenExpired(refresh_token);
    const fetchData = async () => {
        try {
            setLoading(true);
            const { data } = await api.get('/get-user-losses/');
            setData(data.user_losses);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };
    useEffect(() => {
        if (loggedIn) {
            fetchData();
        }
    }, [loggedIn]);

    return <UserLossesContext.Provider value={{ data, loading, fetchData }}>{children}</UserLossesContext.Provider>;
}

export function useUserLosses(param) {
    const contextValue = useContext(UserLossesContext);
    if (!param) {
        return contextValue;
    }
    let data = contextValue.data?.filter((x) => x.event_id.trim() === param.trim());
    return { data };
}
