import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import SignupRoutes from './SignupRoutes';
import MainRoutes from './MainRoutes';
import HomeRoutes from './HomeRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([HomeRoutes, MainRoutes, LoginRoutes, SignupRoutes]);
}
