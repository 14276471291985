// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    isLoading: false,
    isLoggedIn: false,
    user: null,
    error: null
};

// ==============================|| SLICE - ACCOUNT ||============================== //

const account = createSlice({
    name: 'account',
    initialState,
    reducers: {
        loginRequest(state, action) {
            state.isLoading = true;
            state.error = null;
        },
        loginSuccess(state, action) {
            state.isLoggedIn = true;
            state.isLoading = false;
            state.error = null;
            if (action.payload) {
                state.user = action.payload;
            }
        },
        loginFailure(state, action) {
            state.isLoggedIn = false;
            state.isLoading = false;
            state.user = null;
            state.error = action.payload;
        },
        logout(state, action) {
            state.isLoggedIn = false;
            state.isLoading = false;
            state.user = null;
            state.error = null;
        }
    }
});

export default account.reducer;

export const { loginRequest, loginSuccess, loginFailure, logout } = account.actions;
