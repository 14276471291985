import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import PrivateRoute from 'layout/PrivateLayout/index';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));
const Help = Loadable(lazy(() => import('pages/help')));
const Payment = Loadable(lazy(() => import('pages/payment')));
const SubscriptionSuccess = Loadable(lazy(() => import('pages/payment/success')));
const ManageMyAssets = Loadable(lazy(() => import('pages/ManageMyAssets')));
const Account = Loadable(lazy(() => import('pages/account')));
const HistoricalEvent = Loadable(lazy(() => import('pages/HistoricalEvent')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: (
                <PrivateRoute>
                    <DashboardDefault />
                </PrivateRoute>
            )
        },
        {
            path: '/help',
            element: (
                <PrivateRoute>
                    <Help />
                </PrivateRoute>
            )
        },
        {
            path: '/manage-my-assets',
            element: (
                <PrivateRoute>
                    <ManageMyAssets />
                </PrivateRoute>
            )
        },
        {
            path: '/historical-events',
            element: (
                <PrivateRoute>
                    <HistoricalEvent />
                </PrivateRoute>
            )
        },
        {
            path: '/payment',
            element: (
                <PrivateRoute>
                    <Payment />
                </PrivateRoute>
            )
        },
        {
            path: '/payment/success',
            element: (
                <PrivateRoute>
                    <SubscriptionSuccess />
                </PrivateRoute>
            )
        },
        {
            path: '/account',
            element: (
                <PrivateRoute>
                    <Account />
                </PrivateRoute>
            )
        }
    ]
};

export default MainRoutes;
