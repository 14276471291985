import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// scroll bar
import 'simplebar/src/simplebar.css';

// third-party
import { Provider as ReduxProvider } from 'react-redux';

// apex-chart
import 'assets/third-party/apex-chart.css';

import 'leaflet/dist/leaflet.css';

// project import
import App from './App';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from 'store';
import reportWebVitals from './reportWebVitals';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StormsProvider } from 'contexts/StormsContext';
import { HistoricalEventsProvider } from 'contexts/HistoricalEventsContext';
import { UserDataProvider } from 'contexts/UserDataContext';
import { UserLossesProvider } from 'contexts/UserLossesContext';

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <StrictMode>
        <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <BrowserRouter>
                        <StormsProvider>
                            <HistoricalEventsProvider>
                                <UserLossesProvider>
                                    <UserDataProvider>
                                        <App />
                                    </UserDataProvider>
                                </UserLossesProvider>
                            </HistoricalEventsProvider>
                        </StormsProvider>
                    </BrowserRouter>
                </LocalizationProvider>
            </PersistGate>
        </ReduxProvider>
    </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
