// material-ui
import { useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, useMediaQuery } from '@mui/material';

// project import
import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';
import Logo from 'components/Logo';

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = () => {
    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

    // common header
    const mainHeader = (
        <Toolbar>
            <Logo />
            <HeaderContent />
        </Toolbar>
    );

    // app-bar params
    const appBar = {
        position: 'fixed',
        color: 'inherit',
        elevation: 0,
        sx: {
            borderBottom: `1px solid ${theme.palette.divider}`
            // boxShadow: theme.customShadows.z1
        }
    };

    return <>{!matchDownMD ? <AppBarStyled {...appBar}>{mainHeader}</AppBarStyled> : <AppBar {...appBar}>{mainHeader}</AppBar>}</>;
};

export default Header;
