import PropTypes from 'prop-types';
import { useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    ButtonBase,
    CardContent,
    ClickAwayListener,
    Paper,
    Popper,
    Stack,
    Typography,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import { EditOutlined, LogoutOutlined, QuestionOutlined, HomeOutlined, SettingOutlined } from '@ant-design/icons';

// project import
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';

// assets
import avatarDefault from 'assets/images/users/avatar-default.png';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from 'store/reducers/account';
import Cookies from 'js-cookie';

// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
    return (
        <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
            {value === index && children}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
    const theme = useTheme();
    const { user } = useSelector((state) => state.account);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = async () => {
        Cookies.remove('access_token');
        Cookies.remove('refresh_token');
        dispatch(logout());
        navigate('/login');
    };

    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const iconBackColorOpen = 'grey.300';

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
            <ButtonBase
                sx={{
                    p: 0.25,
                    bgcolor: open ? iconBackColorOpen : 'transparent',
                    borderRadius: 1,
                    '&:hover': { bgcolor: 'secondary.lighter' }
                }}
                aria-label="open profile"
                ref={anchorRef}
                aria-controls={open ? 'profile-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
                    <Avatar alt="profile user" src={avatarDefault} sx={{ width: 32, height: 32 }} />
                    <Typography variant="subtitle1">{user?.username}</Typography>
                </Stack>
            </ButtonBase>
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 9]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions type="fade" in={open} {...TransitionProps}>
                        {open && (
                            <Paper
                                sx={{
                                    boxShadow: theme.customShadows.z1,
                                    width: 290,
                                    minWidth: 240,
                                    maxWidth: 290,
                                    [theme.breakpoints.down('md')]: {
                                        maxWidth: 250
                                    }
                                }}
                            >
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MainCard elevation={0} border={false} content={false}>
                                        <CardContent sx={{ p: '0 !important' }}>
                                            <List component="nav">
                                                <ListItemButton
                                                    onClick={() => {
                                                        navigate('/');
                                                        setOpen(false);
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <HomeOutlined />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Home Page" />
                                                </ListItemButton>
                                                {(user.user_level >= user.user_levels.business || user.is_demo) && (
                                                    <ListItemButton
                                                        onClick={() => {
                                                            navigate('/historical-events');
                                                            setOpen(false);
                                                        }}
                                                    >
                                                        <ListItemIcon>
                                                            <HomeOutlined />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Historical Events" />
                                                    </ListItemButton>
                                                )}
                                                {user.user_level >= user.user_levels.business && (
                                                    <ListItemButton
                                                        onClick={() => {
                                                            navigate('/manage-my-assets');
                                                            setOpen(false);
                                                        }}
                                                    >
                                                        <ListItemIcon>
                                                            <EditOutlined />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Manage My Assets" />
                                                    </ListItemButton>
                                                )}
                                                <ListItemButton
                                                    onClick={() => {
                                                        navigate('/help');
                                                        setOpen(false);
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <QuestionOutlined />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Help" />
                                                </ListItemButton>
                                                <ListItemButton
                                                    onClick={() => {
                                                        navigate('/account');
                                                        setOpen(false);
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <SettingOutlined />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Manage My Account" />
                                                </ListItemButton>
                                                <ListItemButton onClick={handleLogout}>
                                                    <ListItemIcon>
                                                        <LogoutOutlined />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Log Out" />
                                                </ListItemButton>
                                            </List>
                                        </CardContent>
                                    </MainCard>
                                </ClickAwayListener>
                            </Paper>
                        )}
                    </Transitions>
                )}
            </Popper>
        </Box>
    );
};

export default Profile;
