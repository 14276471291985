import { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useAxios from 'utils/useAxios';
import Cookies from 'js-cookie';
import { isRefreshTokenExpired } from 'utils/auth';

const UserDataContext = createContext();

export function UserDataProvider({ children }) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const api = useAxios();
    const { isLoggedIn } = useSelector((state) => state.account);
    const refresh_token = Cookies.get('refresh_token');
    const loggedIn = isLoggedIn && !isRefreshTokenExpired(refresh_token);
    const fetchData = async () => {
        try {
            setLoading(true);
            const { data } = await api.get('/get-user-data/');
            setData(data.user_data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };
    useEffect(() => {
        if (loggedIn) {
            fetchData();
        }
    }, [loggedIn]);

    return <UserDataContext.Provider value={{ data, loading, fetchData }}>{children}</UserDataContext.Provider>;
}

export function useUserData() {
    return useContext(UserDataContext);
}
