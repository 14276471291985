export const API_BASE_URL = '/api/';

export const colorPalette = {
    'Other Tracks-true': {
        AVNO: '#f0b6da',
        CMC: '#f5d5a9',
        EMC: '#bdc6f2',
        HWRF: '#b9e6eb',
        UKM: '#A7CE9D',
        OFCL: '#e38981',
        JTWC: '#e38981',
        OTHER: '#8888887f'
    },
    'Hazards-Rain': {
        'Over 24 in': { name: 'Over 24 in', value: '#FEFED8' },
        '12-24': { name: '12-24 in', value: '#D5F2BC' },
        '9-12in': { name: '9-12 in', value: '#A7CE9D' },
        '6-9in': { name: '6-9 in', value: '#97AE91' },
        '3-6in': { name: '3-6 in', value: '#929489' },
        '1-3in': { name: '1-3 in', value: '#8D7982' },
        'Less than 1in': { name: 'Less than 1 in', value: '#855E78' }
    },
    'Hazards-Wave': {
        'over 25 ft': { name: 'Over 25 ft', value: '#996330' },
        '20 to 25 ft': { name: '20 to 25 ft', value: '#9D831E' },
        '15 to 20 ft': { name: '15 to 20 ft', value: '#97A92A' },
        '10 to 15 ft': { name: '10 to 15 ft', value: '#80C55F' },
        '6 to 10 ft': { name: '6 to 10 ft', value: '#66D89C' },
        '3 to 6 ft': { name: '3 to 6 ft', value: '#6CEBDB' },
        'less than 3ft': { name: 'Less than 3 ft', value: '#B3F2FD' }
    },
    'Hazards-Surge': {
        'Over 20 ft': '#ffff66',
        '15 to 20 ft': '#b5e268',
        '12 to 15 ft': '#87ba71',
        '9 to 12 ft': '#64967a',
        '6 to 9 ft': '#467987',
        '3 to 6 ft': '#346499',
        '1 to 3 ft': '#274ca6',
        'Less than 1 ft': '#1a33b3'
    },
    'Hazards-Wind': {
        'Over 165 mph': '#FACCFA',
        '160 - 165 mph': '#FCC4E3',
        '155 - 160 mph': '#FDBCCB',
        '150 - 155 mph': '#FDB4B6',
        '145 - 150 mph': '#FDAC9E',
        '140 - 145 mph': '#FAA587',
        '135 - 140 mph': '#F39E70',
        '130 - 135 mph': '#E69858',
        '125 - 130 mph': '#D69446',
        '120 - 125 mph': '#C29037',
        '115 - 120 mph': '#AF8D2E',
        '110 - 115 mph': '#9B892B',
        '105 - 110 mph': '#868330',
        '100 - 105 mph': '#747E38',
        '95 - 100 mph': '#627941',
        '90 - 95 mph': '#52744A',
        '85 - 90 mph': '#416F53',
        '80 - 85 mph': '#33695A',
        '75 - 80 mph': '#26635F',
        '70 - 75 mph': '#1C5A62',
        '65 - 70 mph': '#195662',
        '60 - 65 mph': '#144D62',
        '55 - 60 mph': '#124761',
        '50 - 55 mph': '#103D5F',
        '45 - 50 mph': '#0E375E',
        '40 - 45 mph': '#0D315D',
        '35 - 40 mph': '#0A285C',
        '30 - 35 mph': '#06215B',
        'Less than 30 mph': '#011959'
    },
    'Track Points-true': {
        'Category 5': '#ff6060',
        'Category 4': '#ff8f20',
        'Category 3': '#ffc140',
        'Category 2': '#ffe775',
        'Category 1': '#ffffcc',
        'Tropical Storm': '#00faf4',
        'Tropical Depression': '#5ebaff'
    },
    'Loss-Admin 0': {
        '> 1000M': '#a00002',
        '100-1000M': '#f10007',
        '10-100M': '#fe460a',
        '1-10M': '#ff8929',
        '< 1M': '#e8dec3'
    },
    'Loss-Admin 1': {
        '> 100M': '#a00002',
        '10-100M': '#f10007',
        '1-10M': '#fe460a',
        '0.1-1M': '#ff8929',
        '< 0.1M': '#e8dec3'
    },
    'Loss-Admin 2': {
        '> 10M': '#a00002',
        '1-10M': '#f10007',
        '0.1-1M': '#fe460a',
        '0.01-0.1M': '#ff8929',
        '< 0.01M': '#e8dec3'
    },
    'Population-Admin 0': {
        '> 1000M': '#2e214d',
        '100-1000M': '#765985',
        '10-100M': '#c36d9a',
        '1-10M': '#d4a6c4',
        '< 1M': '#e6e6f0'
    },
    'Population-Admin 1': {
        '> 100M': '#2e214d',
        '10-100M': '#765985',
        '1-10M': '#c36d9a',
        '0.1-1M': '#d4a6c4',
        '< 0.1M': '#e6e6f0'
    },
    'Population-Admin 2': {
        '> 10M': '#2e214d',
        '1-10M': '#765985',
        '0.1-1M': '#c36d9a',
        '0.01-0.1M': '#d4a6c4',
        '< 0.01M': '#e6e6f0'
    }
};
