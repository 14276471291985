import { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useAxios from 'utils/useAxios';
import Cookies from 'js-cookie';
import { isRefreshTokenExpired } from 'utils/auth';

const StormsContext = createContext();

export function StormsProvider({ children }) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const api = useAxios();
    const { isLoggedIn } = useSelector((state) => state.account);
    const refresh_token = Cookies.get('refresh_token');
    const loggedIn = isLoggedIn && !isRefreshTokenExpired(refresh_token);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await api.get('/storms/');
                setData(response.data);
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        };

        if (loggedIn) {
            fetchData();
        }
    }, [loggedIn]);

    return <StormsContext.Provider value={{ data, loading }}>{children}</StormsContext.Provider>;
}

export function useStormsData() {
    return useContext(StormsContext);
}
